<template>
  <ion-page>
    <ion-header :translucent="true">
      <ion-toolbar>
        <ion-buttons slot="start">
          <ion-button @click="$router.go(-1)" router-link="/">
            <ion-icon :icon="backIcon"
          /></ion-button>
        </ion-buttons>
        <ion-title>IntelectuGal</ion-title>
        <logout-button />
      </ion-toolbar>
    </ion-header>
    <ion-content :fullscreen="true">
      <ion-card>
        <ion-card-header>
          <ion-card-title>{{ word }}</ion-card-title>
        </ion-card-header>

        <ion-card-content>
            <h2>Acepcións</h2>
          <ul>
              <li v-for="acepcion in acepcions" :key="acepcion"> {{acepcion.detalle}}
              </li>
          </ul>
          <h2>Citas</h2>
          <ul>
              <li v-for="cita in citas" :key="cita"> {{cita}}
              </li>
          </ul>
          <h2 v-if="sinonimos.length > 0">Sinónimos</h2>
          <ul>
              <li v-for="sinonimo in sinonimos" :key="sinonimo"> {{sinonimo}}
              </li>
          </ul>
          <h2 v-if="Object.keys(equivalencias).length > 0">Equivalencias</h2>
          <ul>
              <li v-for="equivalencia in Object.entries(equivalencias)" :key="equivalencia[0]"> <strong>{{equivalencia[0]}}: </strong>{{equivalencia[1]}}
              </li>
          </ul>
          <h2 v-if="Object.keys(outras_fontes).length > 0">Outras fontes</h2>
          <ul>
              <li v-for="fonte in Object.entries(outras_fontes)" :key="fonte[0]"> <a :href="fonte[1]">{{fonte[0]}}</a>
              </li>
          </ul>
        </ion-card-content>
      </ion-card>
    </ion-content>
  </ion-page>
</template>

<script>
import LogoutButton from "@/components/LogoutButton.vue";
import { arrowBackOutline } from "ionicons/icons";
import axios from "axios";

import {
  IonPage,
  IonButtons,
  IonHeader,
  IonToolbar,
  IonContent,
  IonCard,
  IonCardHeader,
  IonCardTitle,
  IonCardContent,
  IonIcon,
  IonTitle,
} from "@ionic/vue";

export default {
  name: "Definition",
  props: ["word"],
  components: {
    LogoutButton,
    IonButtons,
    IonHeader,
    IonContent,
    IonCard,
    IonCardHeader,
    IonCardTitle,
    IonCardContent,
    IonTitle,
    IonIcon,
    IonToolbar,
    IonPage,
  },
  data() {
    return {
      backIcon: arrowBackOutline,
      acepcions: [],
      citas: [],
      equivalencias: {},
      etimoloxia: "",
      outras_fontes: {},
      sinonimos: [],
      termo: null,
    };
  },
  computed: {
 
  },
  mounted() {
    this.getWordDefinition();
  },
  methods: {
    getWordDefinition() {
      let self = this;
      axios
        .post(
          "https://aprendizaxe-elitek2.harperdbcloud.com",
          JSON.stringify({
            operation: "search_by_value",
            schema: "aprendizaxe",
            table: "digalego",
            search_attribute: "termo",
            search_value: this.word,
            get_attributes: [
              "id",
              "acepcions",
              "citas",
              "equivalencias",
              "etimoloxia",
              "outras_fontes",
              "sinonimos",
              "termo",
            ],
          }),
          {
            headers: {
              "Content-Type": "application/json",
              Authorization: "Basic ZWxpdGVrOkVsaXRlazIwMTYj",
            },
          }
        )
        .then(function (res) {
          if (res.data.length > 0) {
            const definition = res.data[0];
            self.acepcions = definition.acepcions;
            self.citas = definition.citas;
            self.equivalencias = definition.equivalencias;
            self.etimoloxia = definition.etimoloxia;
            self.outras_fontes = definition.outras_fontes;
            self.sinonimos = definition.sinonimos;
            self.termo = definition.termo;
          }
        });
    },
  },
};
</script>

